.checkbox_setting {
  .ant-checkbox-group {
    display: block;
    .ant-checkbox-wrapper {
      display: block;
      margin-bottom: 0.5rem;
    }
  }
}
.even-row {
  background-color: #f8fcfd;
}
.even-row .ant-table-cell-fix-left,
.even-row .ant-table-cell-fix-right {
  background-color: #f8fcfd !important; /* Màu nền cho cột cố định trong row chẵn */
}

.odd-row {
  background-color: #ffffff;
}
.odd-row .ant-table-cell-fix-left,
.odd-row .ant-table-cell-fix-right {
  background-color: #ffffff !important; /* Màu nền cho cột cố định trong row chẵn */
}

.css-dev-only-do-not-override-umwta.ant-table-wrapper .ant-table-thead > tr > th {
  background-color: #ffffff !important;
}
.ant-table-container {
  border-radius: 12px !important;
  overflow: hidden !important;
  box-shadow: 0px 2px 4px 0px #00000005 !important;
}
