.ant-select-single.ant-select-sm .ant-select-selector {
  font-size: 14px !important;
  color: #646970 !important;
}
.ant-pagination.ant-pagination-mini .ant-pagination-item {
  font-size: 14px !important;
  font-weight: 500 !important;
  width: fit-content !important;
  height: fit-content !important;
  min-width: fit-content !important;
  margin: 0 2px !important;
}
.ant-pagination .ant-pagination-item a {
  color: #646970 !important;
}

.ant-pagination .ant-pagination-item-active a {
  color: #4dabd1 !important;
  border-color: #4dabd1 !important;
}

.ant-pagination .ant-pagination-item a {
  width: 24px !important;
  height: 24px !important;
  display: block !important;
  padding: 0 !important;
}
