.__login {
  .ant-alert {
    align-items: center;
  }
  .ant-alert-error {
    padding: 10px 16px !important;
    background-color: #FEF0EF;
    border: 1px solid #F87171;
    border-radius: 4px;
    .ant-alert-description {
      font-size: 14px;
      color: #0E2026;
      font-weight: 600;
      line-height: 18px;
    }
    .ant-alert-icon {
      margin-inline-end: 6px !important;
    }
  }
}